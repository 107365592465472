import React from 'react'
import './CardImage.css'

function CardImage() {

  return (
    <div className='cardImage'>
      <img src="https://source.unsplash.com/user/erondu/600x400"></img>
    </div>
  )
}

export default CardImage;