import React ,{useState,useEffect,useCallback} from 'react'
import './LandingImage.css'
import Typewriter from 'typewriter-effect';
const TEXTS = ['Forest', 'Building', 'Tree', 'Color'];
const LandingImage = ({images})=>{


    return (
        <div className='landingImage'>
            <div className=' containerText'>
                <div className='typewriterContent landingText'>
                <Typewriter className='landingText'
                    options={{
                        strings: ['Welcome To Keslec Design Solution', 'Structural Design'],
                        autoStart: true,
                        loop: true,
                        cursor:"",
                        pauseFor:2500,
                    }}
                />
                </div>
            </div>
        </div>
    )
}

export default LandingImage
