export const MenuItems = [
    {
        "title":"Home",
        "url":"/",
        "cName":'nav-links'
    },
    {
        "title":"About Us",
        "url":"/#aboutUs",
        "cName":'nav-links'
    },
    {
        "title":"Services",
        "url":"/#service",
        "cName":'nav-links'
    },
    {
        "title":"Projects",
        "url":"/#project",
        "cName":'nav-links'
    },
    {
        "title":"Contact Us",
        "url":"/contactUs",
        "cName":'nav-links'
    }
]