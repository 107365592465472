import React, {useEffect,useState} from 'react'
import './ProjectBody.css'

import { getProjectById } from '../../Api/ProjectAPI'


function ProjectBody({projectId}) {

  const [projectData,setProjectData] = useState();
  useEffect(() => {
    (async () => {
      const data = await getProjectById(projectId);
      console.log(data);
      console.log('Hello');
      setProjectData(data.data.project);
    })();

  }, []);

  return (
    <div className='projectBody'>
        {projectData?
        <div>
          <h3> {projectData['title']} </h3>
          <p>{projectData['description']} </p>
        </div> : "" }
    </div>
  )
}

export default ProjectBody