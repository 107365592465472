import React from 'react'
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import './Card.css'


function Card({id,title,desc}) {
 
  return (
    <div className='card'>
      <CardHeader/>
      <CardBody id={id} title={title} desc={desc} />
    </div>
  )
}

export default Card;



  
 