import React from 'react'
import { useParams } from "react-router-dom";

import ProjectImgCarousel from './ProjectImgCarousel';
import ProjectBody from './ProjectBody';
import { ImageData } from '../../components/Carousel/CarouselData';

import './ProjectDetail.css'

function ProjectDetail() {
  const { id } = useParams();
  
  return (
    <div className='projectDetail'>
        <ProjectImgCarousel slides={ImageData}/>
        <ProjectBody projectId={id}/>
    </div>
  )
}

export default ProjectDetail;