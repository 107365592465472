import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Navbar from "./components/Navbar/Navbar";
import Home from './Pages/Home/Home';
import ProjectDetail from './Pages/ProjectDetail/ProjectDetail';
import ContactUs from './Pages/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';

import './App.css';
import Services from './components/Services/Services';

function App() {
  return (
    <Router>
      <div className='app'>
      <Navbar/>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/project/:id' element={<ProjectDetail/>}/>
        <Route path='/contactUs' element={<ContactUs/>}/>
      </Routes>
      <Footer/>
      </div>
    </Router>
  );
}

export default App;
