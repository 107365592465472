import React from 'react';
import { Link } from 'react-router-dom';

import './CardBody.css'

const capitalizeFirstLowercaseRest = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

function CardBody({id,title,desc}) {
  var projectID = 1;
  
  return (
    <div className='cardBody'>
      <p className='card-text'>{capitalizeFirstLowercaseRest(desc)}</p>
    </div>
  )
}

export default CardBody;

