import React from "react";
import "./AboutUs.css";
import keslec_logo from "../../Assets/images/keslec_logo.jpeg";

function AboutUs() {
  return (
    <div className="aboutUs" id="aboutUs">
      {/* <div className='logor'>
          <img src={keslec_logo} ></img>
        </div> */}

      <h1 className="heading">About Us</h1>
      <div className="textBox">
        <p>
          Keslec Design Solutions is a leading company specializing in
          structural design and engineering solutions. With a dedicated team of
          highly skilled professionals, we strive to deliver innovative and
          reliable designs to meet the diverse needs of our clients. Our
          commitment to excellence and our passion for design have made us a
          trusted partner for numerous projects across various industries.
          <br />
          <br />
          At Keslec Design Solutions, we pride ourselves on our expertise in
          structural design. Our team of experienced engineers and designers
          possesses a deep understanding of structural principles and the latest
          industry standards. Whether it's designing a new building,
          retrofitting an existing structure, or optimizing a complex
          architectural design, we have the knowledge and skills to deliver
          exceptional results.
          <br />
          <br />
          We offer comprehensive design solutions tailored to the unique
          requirements of each project. Our services encompass a wide range of
          structural design disciplines, including steel, concrete, timber, and
          composite materials. From initial concept development to detailed
          analysis and construction documentation, we provide end-to-end
          solutions that ensure the structural integrity, safety, and
          functionality of every project.
          <br />
          <br />
          At Keslec Design Solutions, client satisfaction is at the heart of
          everything we do. We are dedicated to delivering projects on time and
          within budget while maintaining the highest level of quality. Our
          client-centric approach, combined with our technical expertise,
          ensures that our clients receive tailored design solutions that align
          with their goals and aspirations.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
