import React from "react";
import CardBody from "./CardBody.js";
import CardImage from "./CardImage.js";
import "./Card.css";

function Card({ id, title, desc }) {
  return (
    <div className="serviceCard">
      <CardImage/>
      <CardBody id={id} title={title} desc={desc} />
    </div>
  );
}

export default Card;
