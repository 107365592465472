import React,{useEffect,useState} from 'react'
import Card from './Card/Card';
import './Projects.css'

import { getProjects } from '../../Api/ProjectAPI';

function Projects() {
   
  const [projectData,setProjectData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await getProjects();
      console.log(data);
      setProjectData(data.data.projects);
    })();

  }, []);
  
  return (
    <div className='project' id='project'>
        <h1>Our Projects</h1>
        <div className='projectItems'>
            {projectData.map((items,idx) => {
                return(
                  <Card key={idx} id={items['id']} title={items['title']} desc={items['description']}/>
                )
            })}
        </div>
    </div>
  )
}

export default Projects;