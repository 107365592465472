import React,{useState} from 'react'
import {FaAngleLeft ,FaAngleRight} from 'react-icons/fa';


import './ProjectImgCarousel.css'

function ProjectImgCarousel({slides}) {
  const [curr,setCurr] = useState(0);
  const length = slides.length

  if(!Array.isArray(slides) || slides.length <= 0){
    return null;
  }

  const prevSlide = () =>{
    setCurr(curr===0 ? length - 1: curr - 1);
  };

  const nextSlide = () =>{
    setCurr(curr===length - 1? 0 : curr + 1);
  };

  return (
    <div className='projectImgCarousel'>
      <FaAngleLeft className='left-arrow' id="left-arrow" onClick={prevSlide}/>
      <FaAngleRight className='right-arrow' id="right-arrow" onClick={nextSlide}/>
      

      {slides.map((slide,idx)=>{
            return(
                <div className={idx === curr ? 'imgSlide active':'imgSlide'} key={idx}>
                    {idx === curr && (
                        <img src={slide.img} alt="No Image" className='projectImage'/>
                        )}
                </div>
            )
        })}
    </div>
  )
}

export default ProjectImgCarousel