import apiCall from "./Network/NetworkHandler";

export const getProjects = () =>{
    const url = `/get_projects/`
    const params = {};
    const payload = {};
    return apiCall(url,'get',{params,payload});
}

export const getProjectById = (id) =>{
    const url = `/get_project_by_id/${id}`
    const params = {};
    const payload = {};
    return apiCall(url,'get',{params,payload});
}