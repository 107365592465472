import axios from "axios"

const apiCall = (
    baseQuery,
    method,
    data,
) => {
    if(!navigator.onLine){
        throw new Error("No Connection");
    }
    // Local Backend URL : "https://localhost:8000"
    let baseURL = "https://moonlightak.pythonanywhere.com/api";
    baseQuery = `${baseURL}${baseQuery}`;
    const {params, payload} = data;

    let call = axios(
        {
          method: method,
          url: baseQuery,
          params: params,
          data: payload,
        }
      );
    return call
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error in Keslec :: ", error);
      throw error;
    });

};

export default apiCall;
