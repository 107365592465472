import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="container">     
                <div className="row">                       
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="single_footer">
                            <div className="footer-info">
                              <h4>mail</h4>
                              <p>project@keslec.in</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="single_footer">
                            <div className="footer-info">
                              <h4>Phone</h4>
                              <p>+91-9716330679</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="single_footer">
                            <div className="footer-info">
                              <h4>Address</h4>
                              <p>109, Pearl Business Park,  <br/>  Netaji Subhash Place, Delhi-110034</p>
                              
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="single_footer single_footer_address">
                            <h4>Services</h4>
                            <ul>
                                <li><a href="#">Lorem Ipsum</a></li>
                                <li><a href="#">Simply dummy text</a></li>
                                <li><a href="#">The printing and typesetting </a></li>
                                <li><a href="#">Standard dummy text</a></li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        {/* <div className="single_footer single_footer_address">
                            <h4>Subscribe today</h4>
                            <div className="signup_form">                           
                                <form action="#" className="subscribe">
                                    <input type="text" className="subscribe__input" placeholder="Enter Email Address"/>
                                    <button type="button" className="subscribe__btn"><i className="fas fa-paper-plane"></i></button>
                                </form>
                            </div>
                        </div> */}
                        {/* <div className="social_profile">
                            <h4>Reach Us Out at</h4>
                            <div>
                              <ul>
                                  <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                  <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                  <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                                  <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                              </ul>
                            </div>
                        </div>                           */}
                    </div>         
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <p className="copyright">Copyright © 2022 <a href="#Home">Keslec</a>.</p>
                    </div>                 
                </div>                
            </div>
    </div>
  );
}

export default Footer;
