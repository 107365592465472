import React from 'react'
import './CardHeader.css'

function CardHeader() {
    var style = {
        backgroundImage: 'url(' + 'https://source.unsplash.com/user/erondu/600x400' + ')',
    };

  return (
    <div className='cardHeader'>
        <img className='cardImg' src='https://source.unsplash.com/user/erondu/600x400'>
        </img>
    </div>
  )
}

export default CardHeader;