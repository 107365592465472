import React from 'react'
import Navbar from "../../components/Navbar/Navbar";
import Carousel from '../../components/Carousel/Carousel';
import AboutUs from '../../components/AboutUs/AboutUs';
import Projects from '../../components/Projects/Projects';
import {ImageData} from "../../components/Carousel/CarouselData"
import './Home.css'
import LandingImage from '../../components/LandingImage/LandingImage';
import Services from '../../components/Services/Services';

function Home() {
  return (
    <div className="home">
        {/* <Carousel slides={ImageData}/> */}
        <LandingImage images={ImageData}/>
        <AboutUs/>
        <Services/>
        <Projects/>   
    </div>
  )
}

export default Home;