import React from 'react'
import ContactForm from '../../components/ContactForm/ContactForm'
import GoogleMap from '../../components/GoogleMap/GoogleMap'

import './ContactUs.css'

function ContactUs() {
  return (
    <div className='contact'>
      <ContactForm/>         
    </div>
  )
}

export default ContactUs;