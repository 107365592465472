import React from 'react';
import { Link } from 'react-router-dom';

import './CardBody.css'

const capitalizeFirstLowercaseRest = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

function CardBody({id,title,desc}) {
  var projectID = 1;
  
  return (
    <div className='serviceCardBody'>
      <h5><b>{title}</b></h5>
      <a>{desc}</a>
    </div>
  )
}

export default CardBody;

