export const ServiceData=[
    {   
        img:"agsfsd",
        title:"Structural Design",
        description:"Elevate Your Vision with Professional Structural Design: Transforming Concepts into Extraordinary and Reliable Structures, Seamlessly."
    },
    {   
        img:"agsfsd",
        title:"Concept Project Report",
        description:"Unlocking Structural Brilliance: Streamlined concept project reports for superior web-based structural design. Revolutionize your building's foundation."
    },
    {   
        img:"agsfsd",
        title:"Detailed Project Report",
        description:" Unveiling the blueprint to success: Unlocking precision and clarity through our concise Detailed Project Reports."
    },
    {
        img:"",
        title:"Proof Of Structural Design",
        description:"Streamline your structural design process with our efficient and accurate proof checking service. Confidence in every blueprint."
    },
    {
        img:"",
        title:"Repair and Rehabilitation ",
        description:"Revitalize and Restore: Expert repair and rehabilitation solutions, breathing new life into structures for lasting strength and durability."
    },
    {
        img:"",
        title:"Construction Supervision & Project Management ",
        description:" Efficiently oversee your construction projects with expert guidance and seamless management for flawless structural design execution"
    },
    {
        img:"",
        title:"Bill of Quantities",
        description:"Streamline your structural design process with our precise and comprehensive Bill of Quantities service. Efficiency meets accuracy!"
    },
    {
        img:"",
        title:"Survey",
        description:"Streamline structural design with our Survey service: accurate data collection and analysis for smarter construction decisions."
    },
    {
        img:"",
        title:"Geo Investigation",
        description:"Unlocking the Earth's Secrets: Geo Investigation - Your trusted ally for precise and reliable structural design solutions."
    },
]