import React,{useEffect,useState} from 'react'
import Card from './Card/Card.js'
import './Services.css'

import { ServiceData } from './ServicesData';

function Services() {
  
  return (
    <div className='service' id='service'>
        <h1>Our Service</h1>
        <div className='serviceItem'>
            {ServiceData.map((items,idx) => {
                return(
                   <Card key={idx} id={items['img']} title={items['title']} desc={items['description']}/>
                )
            })}
        </div>
    </div>
  )
}

export default Services;