import React, { Component,useState } from 'react'
import { Buttons } from '../Button/Button';
import {MenuItems} from "./MenuItems"
import './Navbar.css'

export default function Navbar() {
    const [clicked,setClicked] = useState(false);
    
    const handleClick = ()=>{
        setClicked(!clicked); 
    };

    return(
            <nav className='NavbarItems'>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 1080.000000 828.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,828.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M5253 8273 c87 -2 227 -2 310 0 84 1 13 3 -158 3 -170 0 -239 -2
-152 -3z"/>
<path d="M5087 7423 c-159 -138 -373 -323 -746 -646 l-104 -90 5 -682 c3 -376
3 -686 -1 -691 -3 -5 -53 -25 -111 -44 -320 -105 -628 -260 -845 -423 -98 -75
-242 -219 -294 -295 -177 -261 -201 -541 -65 -740 163 -236 581 -406 1162
-472 57 -7 105 -11 106 -9 2 2 7 61 10 131 l7 127 -43 5 c-24 3 -104 13 -178
21 -361 43 -635 140 -786 278 -121 111 -144 213 -80 351 55 119 218 275 398
382 113 66 281 152 373 189 50 20 108 45 130 54 82 37 405 141 575 185 547
141 858 175 1495 163 333 -7 462 -16 705 -47 542 -70 941 -213 1095 -393 63
-74 90 -141 90 -228 0 -64 -5 -85 -33 -141 -66 -134 -300 -329 -644 -538 -49
-29 -88 -55 -88 -56 0 -8 245 84 352 131 345 154 538 334 587 545 18 78 14
213 -9 270 -100 253 -518 491 -1120 639 -176 43 -469 96 -585 106 -38 3 -80 8
-92 11 l-23 4 0 638 0 637 -202 150 c-221 162 -357 263 -661 488 l-197 147
-183 -157z m440 -225 c82 -56 175 -121 208 -143 l60 -42 3 -262 c2 -248 1
-263 -15 -257 -10 4 -107 46 -215 94 l-198 87 0 312 c0 172 2 313 5 313 3 0
71 -46 152 -102z m-317 -220 l0 -313 -159 -82 c-87 -46 -163 -83 -170 -83 -8
0 -11 74 -11 270 l0 270 28 24 c94 83 280 226 295 226 16 0 17 -21 17 -312z
m-420 -239 c0 -181 -3 -230 -12 -226 -7 2 -97 33 -200 68 -104 35 -187 67
-185 72 3 9 380 316 390 316 4 1 7 -103 7 -230z m1445 -30 c3 -4 -25 -25 -62
-45 -38 -21 -110 -62 -162 -91 -52 -30 -100 -52 -106 -49 -10 3 -13 56 -14
200 -1 108 1 201 4 206 6 9 328 -200 340 -221z m15 -269 c0 -76 -3 -141 -7
-143 -10 -5 -303 123 -303 133 0 10 294 159 302 153 5 -2 8 -67 8 -143z m-697
53 c92 -42 164 -80 160 -84 -16 -15 -223 -129 -235 -129 -7 0 -34 8 -60 17
l-48 17 0 128 c0 70 3 128 8 128 4 0 83 -34 175 -77z m-343 -29 c0 -87 -2 -95
-18 -90 -10 4 -63 21 -117 40 l-100 33 110 56 c61 31 113 56 118 56 4 1 7 -42
7 -95z m-648 30 c95 -31 176 -59 180 -62 4 -4 -76 -50 -177 -103 -102 -53
-187 -94 -190 -91 -9 10 -5 312 5 312 5 0 87 -25 182 -56z m488 -157 l155 -52
3 -112 c3 -129 13 -115 -114 -161 l-79 -29 -72 20 -73 20 0 184 c0 140 3 183
13 182 6 0 82 -24 167 -52z m750 -162 c0 -165 -2 -185 -17 -194 -12 -6 -48 -2
-110 13 l-93 21 0 120 0 120 103 52 c56 29 105 52 110 52 4 1 7 -82 7 -184z
m-1010 20 c0 -119 -3 -153 -12 -150 -7 2 -92 25 -188 50 -96 26 -182 49 -190
52 -13 4 359 201 383 202 4 1 7 -69 7 -154z m1283 63 c86 -39 154 -75 150 -78
-21 -18 -314 -140 -323 -135 -15 10 -13 285 2 285 7 0 84 -32 171 -72z m-636
-45 l53 -17 0 -78 c0 -43 -3 -78 -7 -78 -5 0 -30 6 -58 13 l-50 13 -3 82 c-2
45 0 82 4 82 4 0 31 -8 61 -17z m813 -238 c0 -57 -4 -106 -9 -109 -9 -6 -310
63 -323 73 -9 7 314 150 325 144 4 -2 7 -51 7 -108z m-1700 34 c263 -64 242
-57 238 -86 -3 -22 -15 -30 -98 -60 -52 -20 -142 -53 -199 -74 -58 -22 -108
-39 -113 -39 -4 0 -8 68 -8 150 0 83 3 150 7 150 4 0 82 -18 173 -41z m896
-50 c44 -11 44 -12 44 -55 0 -49 3 -48 -65 -31 -49 13 -50 14 -53 55 -4 48 0
49 74 31z m-236 -19 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9
0 16 -4 16 -10z m413 -20 c16 0 -14 -20 -30 -20 -7 0 -13 7 -13 16 0 11 5 14
16 10 9 -3 21 -6 27 -6z m-485 -57 l72 -17 0 -141 0 -142 -62 -7 c-48 -5 -85
0 -170 20 l-109 26 3 115 3 116 75 28 c41 16 84 26 95 24 11 -2 53 -12 93 -22z
m660 -95 l3 -115 -88 -26 c-139 -43 -134 -46 -131 75 l3 102 95 42 c52 23 100
41 105 40 6 -2 11 -52 13 -118z m274 60 c87 -17 158 -34 158 -38 0 -4 -35 -17
-77 -30 -43 -13 -112 -34 -153 -47 -116 -35 -112 -37 -109 55 3 78 5 92 17 92
3 0 77 -14 164 -32z m-1286 -205 c-2 -2 -70 10 -150 28 -80 18 -144 34 -142
36 2 3 69 28 148 58 l143 53 3 -85 c2 -47 1 -87 -2 -90z m680 157 c23 -9 24
-14 24 -100 l0 -90 -29 0 c-16 0 -43 -3 -60 -6 l-31 -6 0 112 0 112 36 -7 c20
-3 47 -10 60 -15z m782 -127 l-3 -48 -50 -3 c-27 -1 -81 1 -120 5 l-70 9 89
32 c49 18 96 37 105 42 9 5 24 10 34 10 15 0 18 -7 15 -47z m-1705 -69 c215
-51 242 -59 245 -76 2 -12 -23 -22 -105 -42 -59 -15 -151 -40 -204 -57 -53
-16 -99 -29 -103 -29 -3 0 -6 54 -6 120 0 74 4 120 10 120 6 0 79 -16 163 -36z"/>
<path d="M5410 3550 l0 -1540 1005 0 1005 0 -2 222 -3 223 -575 2 c-316 2
-581 3 -588 3 -10 0 -12 139 -10 676 l3 676 83 34 c286 115 535 276 707 455
78 81 133 149 121 149 -4 0 -64 -35 -134 -77 -253 -152 -462 -238 -754 -307
l-28 -6 0 515 0 515 -415 0 -415 0 0 -1540z m163 1261 c23 -6 77 -17 120 -26
l77 -16 0 -220 0 -219 -27 1 c-16 0 -71 8 -123 17 l-95 17 -3 228 c-1 125 0
227 3 227 4 0 25 -4 48 -9z m430 -91 c50 -11 102 -22 115 -25 22 -5 22 -5 22
-225 0 -203 -1 -220 -17 -220 -15 0 -120 18 -210 36 l-23 4 0 225 c0 145 4
225 10 225 6 0 52 -9 103 -20z m-377 -810 c55 -11 109 -22 122 -25 22 -5 22
-5 22 -225 0 -209 -1 -220 -19 -220 -17 0 -152 23 -208 35 l-23 5 0 225 c0
124 2 225 4 225 2 0 48 -9 102 -20z m374 -63 l115 -23 3 -224 2 -223 -42 7
c-24 3 -77 12 -118 19 l-75 13 -3 227 c-1 125 -1 227 0 227 2 0 55 -11 118
-23z"/>
<path d="M5030 4798 c-25 -5 -69 -14 -97 -19 l-53 -10 0 -220 c0 -120 3 -219
8 -219 9 0 180 29 210 35 22 5 22 5 22 225 l0 220 -22 -1 c-13 -1 -43 -5 -68
-11z"/>
<path d="M4610 4714 c-30 -7 -81 -17 -112 -24 l-58 -11 0 -220 0 -219 43 6
c57 9 180 34 184 38 2 2 2 102 1 222 l-3 219 -55 -11z"/>
<path d="M4983 3877 l-103 -21 0 -219 0 -218 53 6 c28 4 81 12 117 18 l65 12
3 223 c2 207 1 222 -15 221 -10 -1 -64 -11 -120 -22z"/>
<path d="M4538 3787 l-98 -20 0 -219 0 -219 53 6 c28 4 79 12 112 19 l60 12 3
222 c2 207 1 222 -15 221 -10 -1 -62 -11 -115 -22z"/>
<path d="M7645 3514 c-117 -18 -201 -90 -237 -206 -21 -67 -23 -91 -23 -288 0
-197 2 -221 23 -288 27 -86 84 -150 150 -170 144 -43 398 -41 517 4 79 30 133
106 138 191 l2 48 -47 3 c-44 3 -48 1 -48 -20 0 -13 -9 -42 -20 -66 -15 -34
-29 -48 -67 -65 -44 -20 -64 -22 -218 -22 -113 0 -183 5 -207 13 -47 17 -93
69 -110 124 -18 59 -18 446 0 506 19 65 64 109 129 128 77 22 328 16 391 -10
53 -23 88 -64 97 -115 l6 -39 47 2 47 2 -3 40 c-4 51 -45 133 -82 162 -64 50
-132 66 -300 68 -85 1 -168 0 -185 -2z"/>
<path d="M6592 3023 l3 -488 313 -3 312 -2 0 50 0 50 -265 0 -265 0 0 180 0
180 255 0 255 0 0 50 0 50 -255 0 -255 0 2 163 3 162 263 3 262 2 0 45 0 45
-315 0 -315 0 2 -487z"/>
<path d="M2610 2560 l0 -490 50 0 50 0 0 245 0 245 53 0 53 0 244 -245 245
-245 67 0 c38 0 68 4 68 8 0 5 -117 122 -260 262 -143 139 -260 258 -260 264
-1 6 99 109 222 228 l223 218 -72 0 -71 0 -198 -200 -198 -200 -58 0 -58 0 0
200 0 200 -50 0 -50 0 0 -490z"/>
<path d="M3610 2560 l0 -490 315 0 315 0 0 50 0 50 -265 0 -265 0 0 180 0 180
250 0 250 0 0 50 0 50 -250 0 -250 0 0 165 0 165 265 0 265 0 0 45 0 45 -315
0 -315 0 0 -490z"/>
<path d="M4535 3033 c-116 -35 -160 -104 -159 -248 0 -67 5 -99 18 -125 51
-98 107 -119 355 -131 207 -10 243 -20 274 -80 14 -27 17 -52 15 -117 -3 -79
-5 -85 -34 -112 -19 -16 -53 -32 -83 -39 -67 -14 -284 -14 -335 0 -59 16 -94
56 -102 118 l-7 51 -48 0 c-45 0 -49 -2 -49 -25 0 -108 60 -199 153 -232 43
-15 83 -18 232 -18 163 1 185 3 237 24 103 40 141 110 140 256 0 124 -32 185
-122 230 -60 30 -95 35 -292 45 -137 6 -174 11 -200 27 -58 33 -76 140 -38
219 32 65 65 74 266 74 219 0 258 -17 272 -117 l7 -48 50 0 50 0 -3 40 c-10
128 -52 178 -177 211 -72 20 -354 17 -420 -3z"/>
<path d="M4116 1759 c-53 -13 -72 -31 -85 -84 -14 -56 -13 -179 2 -229 18 -59
52 -78 144 -84 131 -7 176 24 189 131 l7 58 -84 -3 c-67 -2 -84 -6 -84 -18 0
-11 15 -16 62 -18 l62 -3 -5 -32 c-4 -18 -17 -42 -31 -54 -20 -20 -35 -23 -98
-23 -63 0 -78 3 -102 24 -26 22 -27 29 -31 122 -5 159 28 195 167 179 60 -6
78 -17 92 -52 12 -31 39 -30 39 1 0 74 -118 114 -244 85z"/>
<path d="M5681 1761 c-18 -5 -45 -21 -60 -35 -25 -26 -26 -29 -26 -161 l0
-135 32 -31 c29 -30 38 -33 111 -37 99 -6 147 9 173 52 16 26 19 50 19 149 0
169 -19 195 -150 202 -36 2 -80 0 -99 -4z m151 -41 c21 -6 42 -19 48 -30 5
-10 10 -68 10 -128 0 -139 -9 -153 -98 -160 -139 -10 -162 12 -162 156 0 113
12 149 53 161 44 13 105 13 149 1z"/>
<path d="M7543 1761 c-72 -18 -93 -63 -93 -193 0 -178 21 -203 170 -203 150 0
170 24 170 202 0 118 -14 163 -58 182 -34 16 -147 23 -189 12z m177 -51 c25
-14 33 -59 28 -174 -4 -126 -9 -131 -128 -131 -122 0 -124 3 -128 148 -3 102
-2 115 17 139 12 15 34 29 49 31 39 7 140 -1 162 -13z"/>
<path d="M2590 1560 l0 -200 103 0 c180 0 210 21 223 159 10 104 -6 177 -46
214 -30 26 -33 27 -155 27 l-125 0 0 -200z m255 136 c42 -43 46 -218 5 -268
-16 -21 -28 -23 -119 -26 l-101 -4 0 161 0 161 95 0 c87 0 98 -2 120 -24z"/>
<path d="M3050 1560 l0 -200 130 0 c123 0 130 1 130 20 0 19 -7 20 -110 20
l-110 0 0 75 0 75 105 0 c98 0 105 1 105 20 0 19 -7 20 -105 20 l-105 0 0 65
0 65 110 0 c103 0 110 1 110 20 0 19 -7 20 -130 20 l-130 0 0 -200z"/>
<path d="M3464 1748 c-49 -23 -60 -118 -20 -165 22 -25 30 -28 118 -33 112 -6
128 -15 128 -75 0 -55 -17 -68 -96 -73 -81 -5 -121 10 -129 49 -6 27 -28 39
-39 21 -12 -19 15 -79 41 -93 40 -21 191 -19 226 3 31 21 49 78 39 127 -12 55
-48 74 -151 81 -100 6 -121 17 -121 66 0 53 20 64 115 64 89 0 115 -12 115
-51 0 -12 7 -19 20 -19 31 0 26 46 -9 81 -28 28 -33 29 -123 28 -51 0 -103 -5
-114 -11z"/>
<path d="M3854 1746 c-3 -7 -4 -96 -2 -197 3 -176 4 -184 23 -184 19 0 20 8
23 198 2 193 2 197 -18 197 -11 0 -23 -6 -26 -14z"/>
<path d="M4480 1560 c0 -193 1 -200 20 -200 19 0 20 7 20 180 0 99 3 180 8
180 4 -1 60 -82 125 -180 109 -166 120 -180 147 -180 l30 0 0 200 c0 193 -1
200 -20 200 -19 0 -20 -8 -22 -177 l-3 -176 -115 176 c-115 176 -115 176 -152
176 l-38 1 0 -200z"/>
<path d="M5219 1753 c-48 -13 -65 -123 -26 -169 21 -24 31 -27 119 -33 112 -7
128 -16 128 -76 0 -56 -17 -69 -99 -73 -85 -5 -117 8 -126 47 -7 32 -31 42
-41 17 -3 -8 2 -31 11 -50 20 -41 58 -56 140 -56 112 0 157 32 157 114 0 86
-42 116 -159 116 -85 0 -113 16 -113 65 0 54 19 65 115 65 89 0 115 -12 115
-51 0 -12 7 -19 20 -19 38 0 17 79 -27 99 -22 10 -179 14 -214 4z"/>
<path d="M6060 1560 l0 -200 130 0 c123 0 130 1 130 20 0 19 -7 20 -110 20
l-110 0 0 180 c0 173 -1 180 -20 180 -19 0 -20 -7 -20 -200z"/>
<path d="M6420 1595 l0 -165 28 -31 c27 -30 34 -32 108 -36 62 -3 86 0 114 14
54 28 60 49 60 226 0 150 -1 157 -20 157 -19 0 -20 -7 -20 -158 0 -198 1 -195
-108 -200 -72 -4 -81 -2 -101 19 -20 21 -21 33 -21 181 0 151 -1 158 -20 158
-19 0 -20 -7 -20 -165z"/>
<path d="M6832 1743 c3 -15 16 -19 76 -21 l72 -3 0 -179 c0 -173 1 -180 20
-180 19 0 20 7 20 180 l0 180 70 0 c63 0 70 2 70 20 0 19 -7 20 -166 20 -152
0 -165 -1 -162 -17z"/>
<path d="M7280 1560 c0 -193 1 -200 20 -200 19 0 20 7 20 200 0 193 -1 200
-20 200 -19 0 -20 -7 -20 -200z"/>
<path d="M7920 1560 c0 -193 1 -200 20 -200 19 0 20 8 22 182 l3 183 120 -183
c114 -174 121 -182 150 -180 l30 3 3 198 c2 190 2 197 -17 197 -27 0 -33 -49
-29 -212 2 -71 0 -128 -3 -128 -3 0 -57 77 -119 170 -109 164 -114 170 -146
170 l-34 0 0 -200z"/>
</g>
                </svg>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={clicked ? 'nav-menu active' : 'nav-menu'}> 
                    {MenuItems.map((items,idx)=>{
                        return (
                        <li key={idx}>
                            <a className={items.cName} href={items.url}> 
                            {items.title}
                            </a>
                        </li>
                        )
                    })}
                </ul>
                
            </nav>
        );
}

