import React,{useState,useEffect} from "react";
import emailjs from "emailjs-com"
import { Checkmark } from "react-checkmark";
import ClipLoader from "react-spinners/ClipLoader";

import "./ContactForm.css";




function ContactForm() {
  const [inputs, setInputs] = useState({});
  const [showTick,setShowTick] = useState(false);
  const [showLoader,setShowLoader] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  useEffect(() => {
    setShowLoader(false);
    setShowTick(false);
  }, [])
  

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    setShowLoader(true);
    try{
      const response = await sendEmail();
      console.log(response);
      if(response.status === 200)
      {
        console.log("Message Sent");
        setShowTick(true);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
        
      }
      else{
        console.log("Message Not Sent");
        setShowLoader(false);
      }
    }catch (error){
      setShowLoader(false);
      console.log(error);
      alert("Try Again");
    }
  }

  const sendEmail = async (event) => {
    // event.preventDefault();

    inputs.to_name = "Parineet Keshri"
    const response = await emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_KESLEC_TEMPLATE_ID, inputs, process.env.REACT_APP_USER_ID)
    return response;
  }

  return (
    <div className="contact-form">
      <div className="form-header">
          <h2> <b>Contact Us</b> </h2>
      </div>

      <form  className="form-elements" onSubmit={handleSubmit}>
        {!showTick ?
        (<>
          <div className="form-items">
            <div className="form-item">
              <label>Full Name</label>
              <input 
                type="text" 
                name="client_name" 
                value={inputs.client_name || ""} 
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-item">
              <label>Email</label>
              <input 
                type="email"
                name="client_email" 
                value={inputs.client_email || ""} 
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-item">
              <label>Phone</label>
              <input 
                type="text"
                name="client_phone" 
                value={inputs.client_phone || ""} 
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-item">
              <label> Subject </label>
              <input 
                type="text"
                name="client_subject" 
                value={inputs.client_subject || ""} 
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-item" id="form-message">
              <label>Message</label>
              <textarea  name="client_message" value={inputs.client_message || ""} onChange={handleChange} required>
                
              </textarea>
            </div>
          </div>

          {!showLoader ? <><div className="submit-btn">
            <button type="submit" className="btn btn-light"> Send </button>
          </div></> : <>
                        <ClipLoader
                        color="white"
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className="contact-form-loader"
                      /></>}
        </>) : (<>
          <div className="green-tick">
            <Checkmark size={'190'} />
            <div className="message-sent-text">
              Your message has been sent. 
              <br></br>
              We will connect with you soon!!!
            </div>
          </div>
        </>)}
      </form>
    </div>
  );
}

export default ContactForm;
